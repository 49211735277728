.in-game-hud-container {
    position: absolute;
    top: 22px;
    left: 18px;
    padding: 20px 0 0 0;

    .meters-holder {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-content: center;
        align-items: center;

        border: 0px solid #FFF;
        border-top-right-radius: 50em;
        border-bottom-right-radius: 50em;

        background-color: #FFF;
        color: #4E4B66;
        font-size: 60px;
        font-weight: 600;

        width: 320px;
        height: 110px;
        margin: 0;
        padding-left: 30px;
    }

    .collected-holder {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-content: center;
        align-items: center;

        border: 0px solid #FFF;
        border-top-right-radius: 50em;
        border-bottom-right-radius: 50em;

        background-color: #FFF;
        color: #4E4B66;
        font-size: 48px;
        font-weight: 600;

        width: 250px;
        height: 76px;
        margin: 10px 0 0 0;
        padding-left: 100px;

        margin-left: 75px;
        margin-top: 44px;
    }

    .collected-icon {
        position: absolute;
        left: 15px;
        top: 24px;
        width: 152px;
    }
}

.game-progress-bar-container {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%, 50%);



    .game-progress-bar {
        position: relative;
        background-color: #000;
        width: 1100px;
        height: 24px;
        border: #9146ff 2px solid;
        border-radius: 50em
    }

    .game-progress-bar-inner {
        background-color: #fa1ed2;
        width: 0%;
        height: 100%;
        border-radius: 50em;
    }

   

    .game-progress-bar-dino {
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -20px;
        margin-left: 0px;
        width: 55px;
        height: 54px;
        transform: translate(-50%, 0);
    }
}


.chat-progress-bar-container{

    position: absolute;
    /* width: fit-content; */
    min-width:940px;
    right:65px;
    top:65px;

    
    .twitch-speech-bubble{ 
        position:absolute;
        top:-50px;
        right:-60px;
        z-index:10;
        width:130px;
        height:130px;
        background-image: url(../assets/img/twitch-speech-bubble.png);  
    }

    .chat-progress-message
    {
        position: absolute;
        left: 0;
        top: 0;
        // right:0;
        height:36px;
        /* background-color:#FFFFFF; */
        padding-top:7px;
        padding-right: 80px;
        padding-left: 20px;
        font-size:18px;
        color:#FFF;
        text-align: left;
        /* width:490px; */
        border-radius: 5px;

        z-index: 100;

        white-space: nowrap;

        .pinktext{
            color:#fa1ed2;
        }
    }


    .chat-progress-bar{
        margin-top: 25px;
        margin-left: -10px;

        position: relative;
        background-color: #000;
        /* width: fit-content; */
        min-width: 940px;
        height: 45px;
        border: #9146ff 2px solid;
        border-radius: 50em !important;
        overflow: hidden;
    }
    .chat-progress-bar-inner {
        position: absolute;
        height: 100%;
        width: 100%;
        content: "";
        background-color: #fa1ed2;
        top:0;
        bottom: 0;
        left: -10%;
        border-radius: inherit;
    }
}