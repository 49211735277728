.first-preload-view {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;

    pointer-events: none;

    .left-curtain {
        position: absolute;
        right: 50vw;
        top: 0;
        width: 50vw;
        height: 100vh;
        background-color: #9146ff;
/* background-image: url('../assets/img/first-loader/curtain-left-logo.png');*/
        background-repeat: no-repeat;
        background-position: right center;
        background-size: cover;
    }

    .right-curtain {
        position: absolute;
        left: 50vw;
        top: 0;
        width: 50vw;
        height: 100vh;
        background-color: #9146ff;

        /*background-image: url('../assets/img/first-loader/curtain-right-logo.png');*/
        background-repeat: no-repeat;
        background-position: left center;
        background-size: cover;
    }

    .top-cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 20vh;
        margin-top: -20vh;
        background-color: #9146ff;
    }
    
    .bottom-cover {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: 20vh;
        margin-bottom: -20vh;
        background-color: #9146ff;
    }

    .vid-preload-holder {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .loading-bar {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 400px;
        height: 40px;
        background-color: #2A00A2;
        padding: 5px;
        transform: translate(-50%, -50%);

        border: solid 0px #ffffff;
        border-radius: 50em;

        .fill {
            width: 0px;
            height: 30px;
            background-color: #ffffff;
            transition: width 0.1s ease-in-out;

            border: solid 0px #ffffff;
            border-radius: 50em;
        }
    }
}
