// @use "../../../scss-fragments/global";
// @use "../../../scss-fragments/forms";

.drop-down-canceller {
    width: 90vw;
    height: 90vh;
    position: fixed;
    left: 0;
    top: 0rem;
    z-index: 999;
}

.drop-list-holder {
    // @include global.unselectable;
    position: relative;

    svg {
        width: 20px;
        margin-left: 10px;
        color: #D9DBE9;
    }

    .drop-down-closed {
        // @include global.pill-style;
        margin-bottom: 0;
        margin-top: 0;

        font-weight: 500;
        border-color: #FFF;
        border: 2px solid #FFF;
        border-radius: 40px;
        color: #9146FF;
        background-color: #FFF;
        height: 80px;
        box-shadow: 0px 8px rgba($color: #a0a3bd, $alpha: 0.4) !important;

        padding: 10px 20px;
        font-size: 44px;

        cursor: pointer;
    }

    .top-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-items: center;
    }

    .drop-down-list {
        // @include global.pill-style;
        border: 2px solid #FFF;
        border-radius: 40px;
        margin-bottom: 0;
        margin-top: 0;
        box-shadow: 0px 8px rgba($color: #a0a3bd, $alpha: 0.4) !important;

        position: absolute;
        left: 0;
        top: 0;
        z-index: 10001;
        min-width: 100%;
        white-space: nowrap;

        font-weight: 500;
        border-color: #FFF;
        color: #9146FF;
        background-color: #FFF;
        height: fit-content;

        display: flex;
        flex-direction: column;
        gap: 0px;

        padding: 5px 20px;
        font-size: 44px;

        transition: all 0.3s ease-in-out;
    }

    .item {
        width: 100%;
        cursor: pointer;
    }

    .label-holder {
        flex-grow: 1;
    }

    .default-selected {
        border-color: #FFF;
        color: #9146FF;
        background-color: #FFF;
    }

    .drop-down-disabled {
        border-color: #FFF;
        color: #9146FF;
    }

    .option {
        // @include global.standard-rollover-transition;
        transition: all 0.3s ease-in-out;
        color: #BCA4FF;
    }

    .option:hover {
        color: #9146FF;
    }
}
