h2 {
    font-size: 48px !important;
    color: #9146FF;
}

a, a:hover, a:active, a:visited {
    color: #9146FF !important;
    text-decoration: none !important;
}

.dark-text {
    color: #4E4B66 !important;
}

.switched-off-bg {
    width: 100vw;
    height: 100vh;
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/gen_menu_bg.png');
    background-size: cover;
    position: absolute;
    left: 0px;
    top: 0px;
}

.menu-bg {
    width: 1920px;
    height: 1080px;
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/gen_menu_bg.png');
    position: absolute;
    left: 0px;
    top: 0px;

    .ground-full {
        width: 1920px;
        height: 310px;
        background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/main_menu_ground.png');
        background-position: center top;
        background-repeat: no-repeat;
        position: absolute;
        left: 0px;
        bottom: 0px;
    }

    .gen-dino {
        position: absolute;
        left: 476px;
        bottom: 270px;
        width: 180px;
        height: 180px;
        background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/menu_dino.png');
    }
}
