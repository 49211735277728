@use "../scss-fragments/global";
.colourOverlay {
   width:1920px;
   height:1080px;
   background-color:#000000;
   opacity: .4;
   position: absolute;
   left:0;
   top:0;
}

.scaled-gm-holder {
   position: fixed;
   /* overflow: hidden; */
   z-index: 10000;
}

.game-mode-container{
   top:20%;
   position: absolute;
}
 
 .boxlayout {
   padding: 10px;
   margin: 10px;
 }

 .login-stars {
   position: absolute;
   left: 640px;
   top: -18px;
   width: 640px;
 }

 .select-mode-holder {
   width: 1920px;
   height: 1080px;

   position: relative;
 }

.login-content {
   width: 1920px;
   height: 1080px;
   position: absolute;
   top: 0;
   left: 0;

   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;

   .side-margin {
      width: 88px;
   }

   .top-margin {
      height: 400px;
   }

   p {
      font-size: 34px;
      color: #FFFFFF;
      margin: 0;
      padding: 0;
      max-width: 500px;
      line-height: 55px;
   }
   .twitchLogo{
      position: absolute;
      top:5px;
      width:600px;

      img{
         width:600px;
      }
   }

   background-repeat: no-repeat;
   background-size: contain;
   background-position: bottom center;
   background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/login-controllers.png');
}
input {
   width:400px;
   height:50px;
   border-radius: 30px;
   font-size: 40px;
   padding-left:20px;
}
button {
   width: 400px;
   height:30px;
   border-radius:100px;
   background-color: #333;
   color:#FFFFFF;
   font-size:20px;
}
.password{
   position:absolute;
   width:1920px;
   text-align: center;
   margin-top:500px;
}
.mobile-login-content {
   position: absolute;
   top: 0;
   left: 0;

   min-height: 100%;
   max-width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;

   .side-margin {
      width: 88px;
   }

   .top-margin {
      height: 220px;
   }

   p {
      font-size: 30px;
      color: #FFFFFF;
      margin: 0;
      padding: 0;
      max-width: 550px;
      line-height: auto;
   }
   .twitchLogo{
      position: absolute;
      top:20px;
      width:80vw;

      img{
         width:80vw;
      }
   }
   .tw-login-button {
      width:80vw;
      height:90px;
      font-size: 30px !important;
      padding:10px !important;

   }
   .speedLines{
      position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 4;
    pointer-events: none;
   }
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   background-image: url('https://d17sm93bt61fgk.cloudfront.net/game_assets/playertype_background.png');
   z-index: 9999999
}
.mobile-login{
   display: flex;
   align-content: center;
   align-items: center;
   flex-direction: column;
   flex-grow: 1;
}


.mob-speed-lines {
   position: absolute;
   height: 100vh;
   width: 100vw;
   top: 0;
   left: 0;
   pointer-events: none;

   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/speedlines-1.png');
}

.mob-login-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   flex-grow: 1;
   height: 100vh;
   width: 100vw;
   position: absolute;
   left: 0;
   top: 0;

   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   background-image: url('https://d17sm93bt61fgk.cloudfront.net/game_assets/playertype_background.png'), url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/speedlines-1.png');

   z-index: 1000000;

   .tw-logo {
      width: 80vw;
      margin-bottom: 60px;

      img {
         width: 80vw;
      }
   }

   .tw-login-button {
      width:80vw;
      min-height:90px;
      height: fit-content;
      font-size: 26px !important;

      margin-top: 60px !important;

      text-align: center;

      white-space: initial !important;
   }

   p {
      font-size: 30px;
      color: #FFFFFF;
      margin: 0;
      padding: 0;
      max-width: 80vw;
      line-height: auto;
      text-align: center;
   }
}
