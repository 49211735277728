@font-face {
    font-family: GameFont;
    src: url(./assets/fonts/RoobertTWITCH-Medium.woff);
    font-weight: 600;
}

body {
    margin: 0;
    padding: 0;
    /*position: fixed;*/

    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
}

canvas {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
}

.view-holder {
    font-family: 'GameFont', sans-serif;

    position: fixed;
    display: flex;
    flex-direction: column;
    margin: 0;
    opacity: 0;
    transition: opacity 0.35s;

    color: #FFFFFF !important;
    font-size:1.8rem !important;
}

.over-canvas {
    z-index: 10001;
}

.under-canvas {
    z-index: 1;
}

.flex-columns {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

.view-row {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

.rel {
    position: relative;
}

.shrink {
    flex-shrink: 1;
}

.grow {
    flex-grow: 1;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.centered {
    text-align: center;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.35s;
}

.example-panel {
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 10px;
}

/* button classes */
.example-button {
    background-color: burlywood;
    border: 0px solid #000;
    border-radius: 50rem;
    width: 300px;
    height: 80px;
    padding: 1px 15px;
    margin: 5px auto;
}

.example-button p {
    font-size: 18pt;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
}

.active-button {
    cursor: pointer;
}

.pure-black {
    background-color: #000;
    border: 5px solid #000;
}
