.speed-lines {
    position: absolute;
    width: 1920px;
    height: 1080px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.speed-lines-f1 {
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/speedlines_1.png');
}

.speed-lines-f2 {
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/speedlines_2.png');
}

.speed-lines-f3 {
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/speedlines_3.png');
}

.speed-lines-f4 {
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/speedlines_4.png');
}

.ground-full-main-menu {
    width: 1920px;
    height: 367px;
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/main_menu_ground.png');
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    bottom: -10px;
}

.star {
    position: absolute;
    width: 399px;
    height: 442px;
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/shooting_star_1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.goat {
    background-image: url('https://d17sm93bt61fgk.cloudfront.net/menu_assets/shooting_star_goat.png');
}
