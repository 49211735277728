@use "../../scss-fragments/global";

.tw-login-button{
   background-color:#9146FF;
   border:0;
 
   padding:10px 40px;
   color:white;
   box-shadow: 0px 12px #4f15ad;
   border-radius: 50em !important;

   width: 520px;
   white-space: nowrap;
   
   height: 125px;
   font-size: 38px !important;

   margin-top: 80px;

   transform-origin: (50%, 50%);
}
.tw-login-button:hover{
   transform: scale(1.05,1.05);
}