.help-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1540px;
    height: 720px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.help-page-1 {
    background-image: url(../../assets/img/help_panel_1.png);
}

.help-page-2 {
    background-image: url(../../assets/img/help_panel_2.png);
}

.help-page-3 {
    background-image: url(../../assets/img/help_panel_3.png);
}

.help-page-4 {
    background-image: url(../../assets/img/help_panel_4.png);
}

.help-content {
    margin-top: 122px;
    margin-left: 825px;
    width: 650px;
    max-width: 650px;
    min-width: 650px;
    text-align: center;
    color: #000;

    h2 {
        color: #000 !important;
        margin-bottom: 50px;
    }

    p {
        font-size: 32px;
    }

    .button-container {
        position: absolute;
        top: 510px;
        left: 836px;
        width: 630px;
        height: 80px;

        display: flex;
        flex-direction: row;

        .buttonIcon {
            width: 80px;
            height: 80px;
            box-shadow: 0px 8px rgba($color: #a0a3bd, $alpha: 0.4);
        }

        .bi-disabled {
            opacity: 0.25;
            cursor: default !important;
        }
    }
}

.help-bottom-buttons {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 1920px;

    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    align-content: center;
    justify-content: center;

    text-align: center;

    .close-button {
        width: 80px;
        height: 80px;

        background-color: #FFF;
        color: #9146FF;
        border: 2px solid #9146FF;

        box-shadow: 0px 8px rgba($color: #a0a3bd, $alpha: 0.4) !important;
    }

    .play-button {
        width: 300px;
        height: 160px;
    }

    svg {
        margin: auto;
    }
}
