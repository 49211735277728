@use "../scss-fragments/global";

 .boxlayout {
   padding: 10px;
   margin: 10px;
 }

 .twitchLogo{
   margin-top:112px;
 }
 .playthroughTitle {
   position: absolute;

   left:50%;
   transform: translate(-50%, -50%);
   background-color:#2A00A2;
   border:0;
 
   padding:10px 40px;
   color:white;
   font-size:2.5rem;
   border-radius: 50px !important;
   p {
      margin-top:-1px;
   }
 }

 .buffering-message {
  position: absolute;

  left:50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color:#2A00A2;
  border:0;

  padding:10px 40px;
  color:white;
  font-size:2.5rem;
  border-radius: 50px !important;

  width: fit-content;
  min-width: 200px;

  p {
      font-size: 44px !important;
     margin-top:5px;
     white-space: nowrap;
  }
}

.buffering-bg {
  width: 1920px;
  height: 1080px;
  background-image: url(https://d17sm93bt61fgk.cloudfront.net/menu_assets/video-gradient.jpg);
  background-size: cover;
}

/*  .playMenu{
   position: absolute;
   left:50%;
   padding-top:550px;
   transform: translate(-50%, -50%);
 } */
 .buttonIcon{
   position: relative;
   width:160px;
   height:160px;
   background-color:#9146FF;
   border:0;
   color:white;
   box-shadow: 0px 12px #4f15ad;
   border-radius: 80px !important;
   padding:0;
   margin:10px;

 }
 .icon {
   position:absolute;
   top:50%;
   transform: translate(-50%, -50%);
 }

.menu-hud-top {
  position: absolute;
  left: 0px;
  top: 30px;
  width: 1920px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 20px;
  padding-right: 20px;

  .profile-pic {
    height: 80px;
  }

  .hud-top-button {
    position: relative;

    width: 80px;
    height: 80px;
    background-color: #FFF;
    color: #9146FF;
    border: 2px solid #FFF;
    border-radius: 50em;
    box-shadow: 0px 8px rgba($color: #a0a3bd, $alpha: 0.4) !important;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 52px;
    }
  }

  .btn {
    cursor: pointer;
  }

  .play-count-holder {
    position: relative;
    padding-left: 130px;

    background-color: #FFF;
    width: 512px;
    height: 94px;
    border: 0px solid #FFF;
    border-radius: 0 50em 50em 0;

    display: flex;
    flex-direction: column;
    gap: 0px;

    .dino-icon {
      position: absolute;
      left: 0px;
      top: -30px;
      width: 146px;
      height: 162px;
    }

    .header-text {
      color: #4e4b66;
      font-size: 14px;
      margin-top: 10px;
    }

    .count-text {
      color: #9146FF;
      font-size: 54px;
      margin-top: -10px;
    }
  }
}
