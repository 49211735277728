.rotate-device {
    position: absolute;
    z-index: 1000000;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.rd-tw-logo {
    width: 80vw;
    max-width: 940px;
}

.rd-playthrough-title {
    background-color: #2A00A2;
    border: 0px solid #2A00A2;

    padding: 0px 30px !important;
    color: white;
    font-size: 32px !important;
    border-radius: 50em;

    margin-top: -30px;

    p {
        margin-top: 5px;
        white-space: nowrap;
    }
}

.rd-icon {
    width: 30vw;
    max-width: 600px;
    margin-top: 30px;
}

.rd-message-1 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 0;
}
.rd-message-2 {
    font-size: 12px;
    margin: 0;
}

@media screen and (orientation: landscape) {
    .rotate-device {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .rd-playthrough-title {
        transform: scale(1.3);
        transform-origin: center;
    }
}

@media screen and (max-width: 700px) {
    .rd-playthrough-title {
        transform: scale(0.8);
        transform-origin: center;
    }
}

@media screen and (max-width: 540px) {
    .rd-playthrough-title {
        transform: scale(0.6);
        transform-origin: center;
    }
}

@media screen and (max-width: 300px) {
    .rd-message-1 {
        font-size: 18px;
    }
    .rd-message-2 {
        font-size: 10px;
    }
}

@media screen and (min-width: 500px) {
    .rd-message-1 {
        font-size: 22px;
    }
    .rd-message-2 {
        font-size: 14px;
    }
}

@media screen and (min-width: 640px) {
    .rd-message-1 {
        font-size: 24px;
    }
    .rd-message-2 {
        font-size: 16px;
    }
}

@media screen and (max-width: 380px) {
    .rd-playthrough-title {
        transform: scale(0.5);
        transform-origin: center;
    }
}

@media screen and (max-width: 280px) {
    .rd-playthrough-title {
        transform: scale(0.4);
        transform-origin: center;
    }
}

@media screen and (max-width: 200px) {
    .rd-playthrough-title {
        transform: scale(0.3);
        transform-origin: center;
    }
}