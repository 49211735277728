@use "../scss-fragments/global";
.colourOverlay {
   width:1920px;
   height:1080px;
   background-color:#000000;
   opacity: 0.4;
   position: absolute;
   left:0;
   top:0;
   z-index: 1;
}

.video-container{
   width: 1920px;
   height: 1080px;
 }
     
 iframe {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 1920px;
   height: 1080px;
   transform: translate(-50%, -50%);
   z-index: -1;
 }
.clips-container {
  width:1920px;
  height:1080px;
  transition: transform 1s ease-out;
  transform: scale(1); 
}
.shrink-video {
  transform: scale(0); 
  transform-origin: 50%, 50%;
  transition: transform .5s ease-in;
}
 .video-container video {
  position: absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  width:960px;
  height:540px;
  border-radius:100px;
  z-index: -2;
 }
 