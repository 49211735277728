@use "../scss-fragments/global";

 .boxlayout {
   padding: 10px;
   margin: 10px;
 }

 .leaderboard-holder {
  position: relative;
  left: 0;
  top: 0;
  width: 1920px;
  height: 1080px;
  margin: 0;
}
.background-box{
  position: relative;
  left: 0;
  top: 0;
  width: 1920px;
  height: 1080px;
  margin: 0;
  z-index: -50;
}

.leaderboardContainer{
  position:absolute;
  width:60%;
  top :40px;
  right:50%;
  transform: translate(50%, 0);
}

.smallLeaderboard {
  transform-origin: var(--transform-origin);
  transform: var(--transform-translate) scale(var(--smallScale)) !important;
}

.scoreContainer{
  width:100%;
  
  height: 900px;
  max-height:900px;
  overflow-y:scroll;
  margin-top:80px;
  padding-top:var(--boxSpacing);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom:20px;

  align-content: flex-start;

  p { 
    width:100%;
    text-align: center;
    margin-left:-50px;
    margin-top:60px;
  }
}



.scoreBar {
  display: flex;
  justify-content: flex-start;
  width:100%;
  max-height:80px;
  margin-top:10px; 
  display: flex;
  flex-wrap: wrap;
  margin-right:80px;
  padding:20px;  opacity:0;
  cursor: pointer;
}

.scoreBar.firstPos{
  color: #9146FF;
}

.scoreBar.isPlayer{
  color:#FFFFFF !important;
}

div.scoreBar:before{
  position:relative ;
  content: '';
  width:100%;
  min-height:80px;
  height:100%;
  border-radius: 50px;
  background-color:#2A00A2;
  padding:20px;
  opacity:.4;
  z-index: -1;
}

.scoreBar:hover:before{
  border:5px solid #FFFFFF;
}

div.scoreBar:nth-of-type(odd):before{
  opacity:.6;
}
div.scoreBar.firstPos:before{
  opacity:1;
  background-color: #FFFFFF;
  border:5px solid #9146FF;
}

div.scoreBar.isPlayer:before{
  opacity:1;
  background-color: #FA1ED2;
  border:5px solid #FFFFFF;
}

div.scoreBar img {
  width:60px;
  height:60px;
  margin-top:-115px;
  border-radius: 50%;
}
.scoreLink{
  padding:0;
  margin:0;
  width:100%;
  color:#FFFFFF !important;
}
.positionBox {
  width:var(--boxHeight);
  width:100px;
  line-height:80px;
  text-align: center;
  margin-top: -80px;
  padding-right:90px;
  padding-left:50px;
}

.playerName {

  flex-grow: 2;
  line-height:80px;
  margin-left:20px;
  margin-top: -80px;
}

.score {
  position:relative;
  text-align: right;
  line-height:80px;
  margin-top: -80px;
  padding-right:50px;
}

.tab-bar{
  position:absolute ;
  width:100%;
  height:2px;
  background-color:#FFFFFF;
  top:30px;

}
.tabs {
  /**
   * Setting display to flex makes this container lay
   * out its children using flexbox, the exact same
   * as in the above "Stepper input" example.
   */
  display: flex;

  width: fit-content;
  position: absolute;
  left:50%;
  transform: translate(-50%, 0);
  margin-left: -50px ;
}

.tabs-switched-off {
  /**
   * Setting display to flex makes this container lay
   * out its children using flexbox, the exact same
   * as in the above "Stepper input" example.
   */
  display: flex;

  width: fit-content;
  position: absolute;
  left:50%;
  transform: translate(-50%, 0);
  margin-left: -50px ;

  background-color: #FFFFFF;
  border: 0px solid #FFF;
  border-radius: 50em;
}

.small-twitch-logo {
  width: 300px;
  position: absolute;
  top: 30px;
  left: 40px;
}

.small-playthrough-title {
  position: absolute;

  left: 190px;
  top: 135px;
  transform: translate(-50%, -50%);
  background-color: #2A00A2;
  border: 0;

  padding: 0px 30px !important;
  color: white;
  font-size: 15px !important;
  border-radius: 50em !important;

  p {
    margin: 7px 0;
    white-space: nowrap;
  }
}

  .tab {
    cursor: pointer;
    padding: 10px 30px;
    background-color: #9146FF;
    color:#FFFFFF;
  
    font-size: 12px;
    line-height:0px;
    text-align: center;
    box-shadow: 0px 5px #5B00A2;
  
  }
  .tab:nth-child(1) {
    border-top-left-radius: 50em;
    border-bottom-left-radius: 50em;
  }
  .tab:nth-child(3) {
    border-top-right-radius: 50em;
    border-bottom-right-radius: 50em;
  }
  .tab.is-tab-selected {
    color: #9146FF;
    background-color: #FFFFFF;
    box-shadow: 0px 5px whitesmoke;
  }
  .tab p:nth-child(1)
  {
    font-size: 20px;
    font-weight: bold;
    padding-top:15px;
    padding-bottom:5px
  }

  .back-button{
    position: absolute;
    top:40px;
    left:40px;
    width:100px;
    height:100px;
    background-color: #FFFFFF;
    color: #9146FF;
    border-radius: 50em;
    padding-left: 50px;
    box-shadow: 0px 5px #d66db4;
    cursor: pointer;
  }

  .game-over-leaderboard {
    .leaderboardContainer {
      top: 160px;
    }
    .scoreContainer{
      height: 620px;
      max-height:620px;
    }
  }

  .ground-full-game-over {
    width: 1920px;
    height: 230px;
    background-image: url(../assets/img/game_over_ground.png);
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    bottom: -10px;

    font-size: 50px;
    padding-right: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .end-game-text {
      margin-left: -40px;
      max-width: 870px;
      line-height: 65px;
    }

    .thumbs-up {
      margin-top: -100px;
      width: 313px;
    }

    .share-icon {
      width: 53px;
    }

    .replay-icon {
      width: 100px;
    }

    .menu-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width:300px;
      width: fit-content;
      height:160px;
      background-color:#9146FF;
      border:0;
      color:white;
      box-shadow: 0px 12px #4f15ad;
      border-radius: 80px !important;
      padding:0 30px;
      margin:10px;
      margin-top: -10px;
      cursor: pointer;
      font-size: 44px;

      p {
        margin: 0;
        margin-right: 20px;
      }
  }
}
