* {
    margin: 0;
    padding: 0;
  }
html,
body {
	height: 100%;
}
body {
	font: 16px/1.2 "Roboto", sans-serif;
	color: #333;
}
a {
	display: inline-block;
	color: #333;
	text-decoration: none;
}
.blog {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	position: absolute;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

/* container */
.container {
	width: 300px;
	height: 400px;
	border-radius: 10px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	position: absolute;
	left:50%;
	transform: translate(-50%, 0%);
	z-index: 1;
    margin-top:20px;
	overflow: scroll;
}

/* leaderboard */
.leaderboard {
	background: linear-gradient(to bottom, #3a404d, #181c26);
}

/* head */
.leaderboard .head {
	padding: 20px 16px;
	color: snow;
	font-size: 20px;
	text-align: center;
}
.leaderboard .head h1 {
	display: inline-block;
	margin-left: 4px;
}
.leaderboard li small
{
    font-size: 20px; 
    font-weight: bold;
}

/* body */
.leaderboard .body {
	color: snow;
	font-size: 16px;
}
.leaderboard ol {
	counter-reset: number; /* 定義和初始化計數器 */
    //margin-left: -40px;
    padding-inline-start: 0px !important
}
.leaderboard li {
	padding: 16px;
	display: flex;
}
.leaderboard li mark {
	flex-grow: 1;
	color: snow;
	background-color: transparent;
    margin-left: 20px;
}
.leaderboard li:before {
	counter-increment: number; /* 遞增計數器 */
	content: counter(number); /* 顯示計數器 */
	margin-right: 4px;
    padding-top:3px;
    font-weight: bold;
    background-color: #ffffff;
    color: #181c26;
    border-radius: 50%;
    width: 30px;
    height: 30px; 
    text-align: center;
}
.leaderboard li:nth-child(1) {
	background: rgb(95, 35, 179);
}
.leaderboard li:nth-child(2) {
	background: rgb(133, 72, 218);
}
.leaderboard li:nth-child(3) {
	background: rgb(95, 35, 179);
}
.leaderboard li:nth-child(4) {
	background: rgb(133, 72, 218);
}
.leaderboard li:nth-child(5) {
	background: rgb(95, 35, 179);
}
