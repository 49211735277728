.bg-grey-out {
    width: 1920px;
    height: 1080px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0px;
    top: 0px;
}

.revive-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;

    .heart-holder {
        position: relative;
        width: 598px;
        height: 598px;
    }

    .grey-heart {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 598px;
        height: 598px;
        background-image: url('../assets/img/big_bleed_heart_grey.png');
        background-repeat: no-repeat;
        background-size: 598px 598px;
        background-position: top center;
    }

    .purple-heart {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 598px;
        background-image: url('../assets/img/big_bleed_heart_purple.png');
        background-repeat: no-repeat;
        background-size: 598px 598px;
        background-position: bottom center;

        transition: height 0.2s ease-out;
    }

    .revive-content-right {
        margin-top: -50px;

        position: relative;
        width: 598px;
        /* height: 598px; */

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: center;

        p {
            text-transform: uppercase;
        }

        .click-icon {
            width: 166px;
        }
    }
}
