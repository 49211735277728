@use "../scss-fragments/global";

.mm-holder {
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-direction: column;

  .mm-buttons {
    height: 285px;
    display: flex;
    flex-direction: row;
    text-align: center;
  }
}

.boxlayout {
  padding: 10px;
  margin: 10px;
}

.twitchLogo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 112px;
  transform-origin: center bottom;
}

.playthroughTitle {
  position: absolute;

  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2A00A2;
  border: 0;

  padding: 0px 50px !important;
  color: white;
  font-size: 44px !important;
  border-radius: 50px !important;

  p {
    margin-top: 5px;
    white-space: nowrap;
  }
}

.playMenu {
  position: absolute;
  left: 50%;
  top: 890px;
  padding-top: 550px;
  transform: translate(-50%, -50%);
}

.buttonIcon {
  position: relative;
  width: 160px;
  height: 160px;
  background-color: #9146FF;
  border: 0;
  color: white;
  box-shadow: 0px 12px #4f15ad;
  border-radius: 50em !important;
  padding: 0;
  margin: 10px 15px;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mm-play-button {
  width: 300px !important;
  height: 160px !important;
  min-width: 300px !important;
  min-height: 160px !important;
  max-width: 300px !important;
  max-height: 160px !important;
}

.mm-round-button {
  width: 160px !important;
  height: 160px !important;
  min-width: 160px !important;
  min-height: 160px !important;
  max-width: 160px !important;
  max-height: 160px !important;
}

.footer {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0%);

  font-size: 20px;
}

.footer-links {
  color: #BCA4FF;
  gap: 30px;
}

.menu-dino-holder {
  position: absolute;
  left: 960px;
  bottom: 320px;
  transform: translate(-50%, 0%);

  .menu-dino-run {
    width: 180px;
  }
}

.menu-jump-object {
  position: absolute;
  right: -280px;
  bottom: 310px;
  width: 180px;
}
