@use "../scss-fragments/global";
.loginBackground {
position: relative;
  background-image: url('https://d17sm93bt61fgk.cloudfront.net/game_assets/playertype_background.png');
  min-width: 1920px;
  min-height: 1080px;

  .dino {
   position:absolute;
   top:0;
   left:50%;
   width: 1206px;
   transform:  translate(-50%, 0%);
    margin-top:240px
   }
}
.speedLines{
   position:absolute;
   top:0;
   left:0
}

.loadingBackground{
   background-image: url('https://d17sm93bt61fgk.cloudfront.net/game_assets/playertype_background.png');
   width:1920px;
   height:1080px;
   text-align:center;
}

.loading-message {
   position: absolute;
   left: 960px;
   top: 740px;
   color: #000;
   font-size: 40px;
   transform: translate(-50%, 0%);
}
