.go-congrats {
    position: absolute;
    left: 65px;
    top: 100px;
    font-size: 120px;
    letter-spacing: -1px;
    line-height: 140px;

    margin-left: 0px; /* 2000px; */
    opacity: 0;

    width: 1430px;
    max-width: 1430px;
    /* white-space: nowrap; */
}

.go-big-end-text {
    position: absolute;
    left: 65px;
    top: 260px;
    font-size: 300px;
    line-height: 250px;
    letter-spacing: -15px;

    margin-left: 2000px;

    width: fit-content;
    white-space: nowrap;
}

.go-small-message {
    font-size: 76px;
    line-height: 82px;
    max-width: 1430px;
    margin: 0;
}

.go-dino {
    left: 940px !important;
}

.go-result-container {
    position: absolute;
    left: 1690px;
    top: 420px;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* text-shadow: 1px 1px 2px #9146FF; */

    margin-top: 200px;
    opacity: 0;

    .distance {
        font-size: 80px;
    }

    .goats {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        font-size: 80px;

        .gh-icon {
            margin-left: 25px;
            width: 284px;
            /* margin-right: 10px; */
            z-index: 100;
        }

        .collected-holder {
            margin-top: -32px;
            background-color: #FFF;
            color: #9146FF;
            white-space: nowrap;
            width: 317px;
            height: 105px;
            text-align: center;
            border: 0px solid #FFF;
            border-radius: 50em;

            p {
                margin-top: -8px;
            }
        }
    }
}

.go-credits-list {
    position: absolute;
    top: 0px;
    left: 720px;
    margin-top: 900px;

    font-size: 50px;
    color: #FFF;
    line-height: 70px;
}

.go-button-container {
    position: absolute;
    left: 0px;
    bottom: 40px;
    width: 1920px;
    z-index: 99999;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 30px 0 60px;
    
    .menu-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width:300px;
        height:160px;
        background-color:#9146FF;
        border:0;
        color:white;
        box-shadow: 0px 12px #4f15ad;
        border-radius: 80px !important;
        padding:0;
        margin:10px;
        cursor: pointer;
    }
}

.go-dino-holder {
    position: absolute;
    left: 1600px;
    bottom: 273px;
    // transform: translate(-50%, 0%);
  
    .go-dino-run {
      width: 180px;
      margin-left: -2000px;
    }
  }
